html {
    background-color: #111317;
}

.blogpost-content h2, .blogpost-content a {
    color: #b1895f;
}

.blogpost-content {
    letter-spacing: .05em !important;
}
  
#blog-title {
    font-size: 44px;
    line-height: 120%;
    -webkit-text-fill-color: transparent;
    background: linear-gradient(.729turn,#ffd3a5 62.89%,#b1895f 77.06%,#986530 96.93%);
        background-clip: border-box;
    -webkit-background-clip: text;
    font-family: Colus;
    margin-top: 50px;
    text-shadow: 0 0 10px rgba(219,159,80,.3);
}

.blogpost-content p  {
    font-size: 21px;
    color: #8d8b8b;
    margin-top: 21px !important;
    margin-bottom: 21px !important;
  }
  

.blogpost-content {
    max-width: 1400px !important;
}

/* .blogpost-content div:first-child {
max-width: 950px !important;
} */

#blog-sidebar-devinfo svg {
    color: white !important;
}

.blogpost-content {
    display: flex;
    flex-direction: row;
}

.blogpost-content .blog-subtext, .mobile-content .blog-subtext {
    color: #8d8b8b !important;
    margin-top: 0 !important;
    line-height: 222%;
    letter-spacing: .05em !important;
    font-size: 16px !important;
}

#blog-sidebar {
    margin-left: 3rem;
}

.blogpost-text i, .mobile-content i {
    color: #fff !important;
    margin-top: 0 !important;
}

@media only screen and (min-width: 993px) and (max-width: 1440px) {
    .blogpost-content {
        margin-left: 2rem !important;
        display: flex;
        flex-direction: row;
    }
    .blogpost-content div:first-child {
        max-width: 920px !important;
    }
    #blog-sidebar {
        margin-left: 2rem !important;
    }
}

@media only screen and (max-width: 1440px) {
    .blogpost-content {
        margin-left: 4% !important;
        justify-content: flex-start;
    }
    .blogpost-content div:first-child {
        max-width: 920px !important;
    }
}

@media only screen and (min-width: 1800px) and (max-width: 1920px) {
    .blogpost-content {
        /* max-width: 950px !important; */
        margin-left: 20% !important;
        margin-top: 80px;
        /* margin-left: 5%; */
    }
}

@media only screen and (min-width: 1441px) and (max-width: 1800px) {
    .blogpost-content {
        /* max-width: 950px !important; */
        margin-left: 5%;
        margin-top: 80px;
    }
}

@media only screen and (min-width: 1921px) {
    .blogpost-content {
        /* max-width: 950px !important; */
        margin-left: 20% !important;
        margin-top: 80px;
    }
}

@media only screen and (max-width: 992px) {
    #blog-sidebar {
        margin-left: 0 !important;
    }
    .mobile-content p {
        color: #8d8b8b;
        font-size: 19px !important;
        line-height: 125% !important;
        margin-bottom: 19px !important;
        margin-top: 19px !important;
    }
    .mobile-content {
      margin-left: 1rem !important;
      line-height: 165%;
      justify-content: flex-start !important;
    }
    .mobile-content > div {
      align-items: flex-start !important;
    }
    .mobile-content li, .mobile-content ul {
      font-size: 17px;
      color: #8d8b8b;
    }
    
    .mobile-content h2 {
      font-size: 22px !important;
    }
  
    .mobile-content h2, .mobile-content a {
      color: #b1895f;
    }
    
    .mobile-content h1 {
      font-size: 32px !important;
      line-height: 130%;
    }
  }
  

.blogpost-content {
    margin-left: 5%;
    width: 100%;
    line-height: 222%;
}

#openbeta-requirements h3 {
    color: white;
}
   