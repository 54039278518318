@import './styles/index.css';
*{
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body::-webkit-scrollbar {
  display: none;
}

.hovered-button{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.hovered-button .on-hover-button {
  position: absolute;
  transition: opacity 1s ease;
  left: 0;
  top: 0;
}

.hovered-button:hover .on-hover-button {
  opacity: 0;
}
.hovered-button span{
  position: relative;
  z-index: 1;
}



.page1{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #111317;
}
.page1 div{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page1 button{
  padding: 0.5em 1em;
  background-color: transparent;
  border: 2px solid #FFD3A5;
  border-radius: 8px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: all 300ms ease;
}
.page1 button:hover{
  transform: scale(1.1);
}
.page1 h3{
  color: green;
}

