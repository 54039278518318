.assistant-header {
    height: 253px;
    width: 100%;
    background: url('../../assets/images/backgrounds/assistant-header-bg.png') no-repeat;
    background-position: 0px -100px;
    display: flex;
    justify-content: center;
}

.assistant-header h2 {
    transform: translateY(90px);
    font-family: 'Colus';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 35px;
    text-align: center;
    letter-spacing: 0.08em;
    background: linear-gradient(262.44deg, #FFD3A5 62.89%, #B1895F 77.06%, #986530 96.93%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-shadow: 0px 0px 10px rgba(219, 159, 80, 0.3);
}

/* ----- */
.assistant-body {
    background-image: url('../../assets/images/backgrounds/Service-status-bg.webp');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    /* height: 1000px; */
}

.assistant-container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    padding: 5rem 0;
}

.assist-controls {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.assist-controls h4 {
    font-family: 'Colus';
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.08em;
    color: #8F8F8F;
}

.assist-controls h4 span {
    font-family: 'Roboto';
    font-size: 18px;
    line-height: 50px;
    text-transform: capitalize;
}

.assist-controls select {
    width: 320px;
    max-width: 40vw;
    height: 50px;
    border-radius: 2px;
    border: 1px solid;
    border-image: linear-gradient(188.43deg, #EDDBAC -269.15%, #FFEA9E -66.47%, #693E18 155.69%) 1/1;
    font-family: 'Colus';
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.08em;
    color: #EDDBAC;
    padding: 0 .5em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    position: relative;
    background: #000000b3 url('../../assets/icons/d-arr.svg') no-repeat;
    background-position: 95% 50%;
    margin-left: 1rem;

}

/* ------ */
.assistant-content {
    width: 100%;
    margin: 3em 0;

}

.assistant-content .tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    gap: 25px;
    padding: 0;

}

.tabs li {
    width: 500px;
    text-align: center;
    position: relative;
    transition: all ease .3s;
    padding: 1em 0em;
    white-space: nowrap;
    cursor: pointer;
    font-family: 'Colus';
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.08em;
}

.tabs li:hover {
    color: #EDDBAC;
}

.tabs li::after {
    transition: all .3s cubic-bezier(1, 0, 0, 1);
    will-change: transform, box-shadow, opacity;
    position: absolute;
    content: '';
    height: 3px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    border-radius: 3px 3px 0px 0px;
    background: #000000;
    box-shadow: 0px 4px 10px 3px rgba(#EDDBAC, .15);
    opacity: 1;
    transform: scale(0.2, 1);
}

.tabs .active {
    color: #EDDBAC;
}

.tabs .active::after {
    background: #EDDBAC;
    opacity: .3;
    transform: scale(1, 1);
}

.assistant-content .box {
    /* min-width: 1280px; */
    width: 100%;
    max-width: 90vw;
    min-height: 200px;
    background: rgb(0, 0, 0, .8);
    border-radius: 4px;
    margin: 2em 0;
    padding: 1rem 0;
}

.box h4 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.04em;
    font-size: 12px;
    font-size: calc(0.6em + .4vw);
    color: #E5E5E5;
}


.issues section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em 2em;
}

.issues section img:last-child {
    transform: rotateZ(180deg);
}

.issues section p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.08em;
    text-transform: capitalize;
    color: #8F8F8F;
}

.issues ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    list-style: none;
}

.issues ul li {
    width: 420.87px;
    height: 260px;
    /* background: rgba(217, 217, 217, 0.1); */
    border: 0.5px solid #C4C4C4;
    animation: issue 500ms linear;
}

@keyframes issue {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.issues ul li div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.issues ul li strong {
    width: 25px;
    height: 25px;
    border: 1px solid #C4C4C4;
    background: #000000;
    color: #C4C4C4;
    font-family: 'Roboto';
    text-align: center;
    line-height: 25px;
    margin: 0 0.5em;
}

.issues ul li h5 {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.08em;
    text-transform: capitalize;
    color: #8F8F8F;
    display: flex;
    align-items: center;
    gap: 10px;
}

.issues section img {
    cursor: pointer;
}

.issues ul li h3 {
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.04em;
    color: #E5E5E5;
    padding: 0 2rem;
}

.issues ul li p {
    font-family: 'Roboto';
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.08em;
    text-transform: capitalize;
    color: #FFFFFF;
    padding: 0 2rem;
}

.report {
    /* padding: 4em 0; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 85%;
    margin: 0 auto;
    transition: all 300ms ease;
}

.report a {
    text-decoration: none;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 16px;
    font-size: calc(.5em + .5vw);
    line-height: 19px;
    letter-spacing: 0.08em;
    color: #E5E5E5;
    text-shadow: 0px 0px 4px #000000;
}

.report span {
    font-family: 'Colus';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.05em;

    background: radial-gradient(318.04% 214.29% at 50.05% -57.14%, #FFEA9E 0%, #693E18 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    display: flex;
}

.assistant-ftr {
    height: 301px;
    width: 100%;
    position: relative;
    z-index: 2;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 10.73%, #000000 41.46%);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.assistant-ftr h1 {
    font-family: 'Colus';
    font-weight: 400;
    font-size: 28px;
    line-height: 35px;
    text-align: center;
    letter-spacing: 0.08em;
    background: linear-gradient(262.44deg, #FFD3A5 62.89%, #B1895F 77.06%, #986530 96.93%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-shadow: 0px 0px 10px rgba(219, 159, 80, 0.3);
}

.assistant-ftr p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.08em;
    text-transform: capitalize;
    color: #8F8F8F;
}

.assistant-ftr span {
    font-family: 'Colus';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #FFF2D1;
    text-shadow: 0px 0px 30px rgba(240, 211, 135, 0.5);
}

.assistant-ftr::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 350px;
    bottom: 0;
    border-bottom: 1px solid;
    border-image: radial-gradient(704.52% 2008517.91% at 49.82% -4.51%, #FFEA9E 0%, #5A4D41 100%) 1/1;
    background: url('../../assets/images/backgrounds/stars.png');
}

/* Lang Modal Styles */

.lang-modal-cont {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blur-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(4px);
}

.lang-modal {
    position: relative;
    width: 860px;
    min-height: 420px;
    background: linear-gradient(180deg, rgba(46, 78, 95, 0.5) 0%, #7C7C7C 100%);
    border: 1px solid #8F8F8F;
    backdrop-filter: blur(10px);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.lang-modal h1 {
    white-space: pre-wrap;
    text-align: center;
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #EDDBAC;
    width: 100%;
    margin: 0;
}

.lang-modal p {

    font-family: 'Roboto';
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.1em;
    text-transform: capitalize;
    text-align: center;
    color: #C4C4C4;
}

.select-menu {
    width: 460px;
    height: 60px;
    background: rgba(20, 23, 18, 0.7);
    border-radius: 4px;
    margin: 1rem 0;
    position: relative;
}

.select-menu input[type=checkbox] {
    display: none;
}


.select-menu label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    height: 60px;
    cursor: pointer;
    user-select: none;
}


.select-menu label span {
    font-family: 'Colus';
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.08em;
    color: #EDDBAC;
}


.select-menu input:checked+label[for=select-menu] img {
    transition: transform 300ms ease;
    transform: rotateZ(180deg);

}

.select-menu ul {
    width: 100%;
    height: 244px;
    background: rgba(20, 23, 18, 0.7);
    border-radius: 4px;
    backdrop-filter: blur(5px);
    padding: 0;
    list-style: none;
    position: absolute;
    top: 42px;
    padding: 1rem 0;
    overflow-y: auto;
    overflow-x: hidden;
}

.select-menu ul li {
    padding: 1rem;
    font-family: 'Colus';
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.08em;
    color: #8F8F8F;
    cursor: pointer;
}

.select-menu ul li:hover {
    color: #EDDBAC;
}

.lang-modal button {
    width: 140px;
    height: 50px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    border: 1px solid;
    border-image: linear-gradient(188.43deg, #EDDBAC -269.15%, #FFEA9E -66.47%, #693E18 155.69%) 1/1;
    cursor: pointer;
    text-align: center;
}

.lang-modal button:disabled {
    opacity: 0.24;
    cursor: not-allowed;
}

#report-success h1 {
    margin-bottom: 4rem;
}

.lang-modal button span {
    font-family: 'Colus';
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.08em;
    background: linear-gradient(139.82deg, #FFD3A5 22.88%, #B1895F 68.47%, #986530 132.42%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    display: block;
}


/* ----- */
.lang-modal-cont.report .lang-modal {
    padding: 5rem 0;
    margin-top: 5em;
}

.close-icon {
    position: absolute;
    top: 5%;
    right: 5%;
    cursor: pointer;
}

.report-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.report-cont input[type=text],
.report-cont input[type="email"],
.report-cont textarea {
    background: rgba(20, 23, 18, 0.7);
    border-radius: 4px;
    width: 460px;
    max-width: 80vw;
    outline: none;
    border: none;
    font-family: 'Roboto';
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.08em;
    color: #C4C4C4;
    padding: 0 1rem;
}

.report-cont input[type=text],
.report-cont input[type="email"] {
    height: 45px;

}

.report-cont textarea {
    resize: none;
    min-height: 245px;
    padding: 1rem;
}

.report-cont section {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.report-cont label,
.report-cont a {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.03em;
    color: #E5E5E5;
}

.report-cont a {
    border-bottom: 1px solid;
    text-shadow: none;
    margin-left: 0.2em;
}

.report-cont section label {
    display: flex;
    align-items: center;
}

.report-cont section input[type=checkbox] {
    display: none;
}

.report-cont section label span {
    width: 20px;
    height: 20px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    margin-right: .5rem;
    border: 1px solid;
    border-image: linear-gradient(188.43deg, #EDDBAC -269.15%, #FFEA9E -66.47%, #693E18 155.69%) 1/1;
}

.report-cont section label span img {
    transform: scale(4);
    width: 100%;
    height: 100%;
}

/* ----------------- */

#player-support-link {
    text-decoration: none;
}

/* MOBILE STYLES */
@media screen and (max-width: 992px) {
    .assistant-header {
        height: 253px;
        background-position: center;
        background-size: cover;
    }
    .assistant-body{
        background: url('../../assets/images/backgrounds/Service-status-bg-mobile.webp') no-repeat;
        background-size: contain;
    }

    .assist-controls {
        flex-direction: column;
    }

    .assistant-container {
        width: 90%;
        padding: 1rem 0;
    }

    .lang-modal {
        width: 95vw;
    }

    .select-menu {
        width: 90%;
    }

    .lang-modal p {
        font-size: 12px;
    }
   
    .assistant-ftr{
        justify-content: center;
        height: 800px;
        gap: 40px;
        position: relative;
    }
    .assistant-ftr::before{
        content: '';
        position: absolute;
        width: 100%;
        height: 42%;
        background: linear-gradient(-180deg, rgba(0, 0, 0, 0) 0.33%, #000000 8.46%);
        z-index: 0;
        top: -30px;
    }
    .assistant-content{
        margin: 0;
    }
    .assistant-ftr h1, .assistant-ftr p{
        z-index: 2;
    }
    .assistant-ftr p{
        padding: 0 5rem;
    }

    .issues ul{
        padding: 0;
    }
    .issues ul li {
        width: 90%;
        background: rgba(217, 217, 217, 0.1);
    }
}